import React from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import RSSIcon from "../assets/rss.svg"
import Layout from "../components/Layout"
import BlogItem from "../components/BlogItem"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import moment from "moment"
import Navbar from "../components/Navbar"
import { shuffle } from "lodash"
import SEO from "../components/seo"
import axios from "axios"
import { tagNameToLabel } from "../utils/constants"

const DirectoryCard = ({ title, description, slug }) => (
  <Link
    to={`/collection/${slug}`}
    css={css`
      text-decoration: none;
    `}
  >
    <div
      css={css`
        margin-bottom: 30px;
      `}
    >
      <h2
        css={css`
          margin: 0;
          color: #0178bd;
        `}
      >
        {title}
      </h2>
      <p
        css={css`
          color: #6b6b6b;
          margin: 10px 0;
        `}
      >
        {description}
      </p>
    </div>
  </Link>
)
function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}
export default function Directory({ data }) {
  const {
    allAirtable: { edges, totalCount },
  } = data

  const tags = new Set()
  for (let edge of edges) {
    const {
      node: { data },
    } = edge
    if (data?.["Tags"]) {
      for (let tag of data["Tags"]) {
        tags.add(tag)
      }
    }
  }

  const tagArray = Array.from(tags)
  tagArray.sort()

  const onRandomBlogClick = () => {
    const randomBlog = edges[Math.floor(Math.random() * edges.length)]
    window.open(randomBlog.node.data["URL"])
  }

  const randomBlogs = edges.slice(0, 10)

  return (
    <Layout>
      <SEO title="Directory" />

      <div css={css``}>
        <div
          css={css`
            text-align: center;
            margin-bottom: 40px;
            margin-top: 40px;
          `}
        >
          <h1
            css={css`
              margin-bottom: 0;
            `}
          >
            Directory
          </h1>
          <span>
            <button
              css={css`
                all: unset;
                color: #0178bd;

                cursor: pointer;
              `}
              onClick={onRandomBlogClick}
            >
              Take me somewhere interesting
              {/* TODO randomlly generate different phrase each time */}
            </button>{" "}
            ✨
          </span>
          <h2>Tags</h2>
        </div>
        <div
          css={css`
            margin: 0 auto;
            max-width: 800px;
          `}
        >
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;

              margin-bottom: 50px;

              a {
                margin: 4px 15px;
              }

              a:hover {
                transition: none;
              }
            `}
          >
            {tagArray.map(tag => (
              <Link
                css={css`
                  font-size: 18px;
                  color: #0178bd;
                  transition: color 1s linear;

                  &:hover {
                    color: ${`rgb(${getRandomInt(0, 200)},
                    ${getRandomInt(0, 200)},
                    ${getRandomInt(0, 200)})
                  })`};
                  }
                `}
                to={`/tag/${tag.replace(".", "dot").replace(/ /g, "-")}`}
              >
                {tagNameToLabel(tag)}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query DirectoryQuery {
    allAirtable(
      filter: {
        data: {
          Flag: { ne: true }
          RSS: { ne: "no" }
          Last_Post_Date: { ne: null }
          dkb_review: { eq: "approved" }
        }
        table: { eq: "main" }
      }
      sort: { order: DESC, fields: data___Last_Post_Timestamp }
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            URL
            RSS
            Flag
            NotCodingSpecific
            Last_Post_Date
            Last_Post_Title
            Last_Post_URL
            Tags
          }
        }
      }
    }
  }
`
